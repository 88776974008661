<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/target`" title="Target" :columns="columns"
                routerpath="/crm/addtarget" :formOptions="formOptions" multiplepath="/crm/addmultipletarget" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        // formTitle: `${this.$route.params.id ? "Edit Sales" : "Add Sales"}`,
        submitRouterPath: "/crm/target",
        method: "post",
        action: "add",
        url: `${baseApi}/target`,
        gridForm: true,
        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          // {
          //   label: "Company Name",
          //   name: "company",
          //   value: "",
          //   type: "dropdown",
          //   class: "col-md-4",
          //   dropdownname: "company",
          //   required: true,
          //   options: [],
          // },

          // {
          //   label: "Company Name",
          //   name: "company",
          //   value: "",
          //   type: "dropdown",
          //   class: "col-md-4",
          //   dropdownname: "company",
          //   required: true,
          //   options: [],
          // },
          {
            label: "Select Team",
            name: "employee",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "team",
            required: true,
            options: [],
          },
          {
            label: "Select Channel Partner",
            name: "channelpartner",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "dealerid",
            required: true,
            options: [],
          },
          {
            label: "Select Franchise",
            name: "franchise",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "franchise",
            required: true,
            options: [],
          },
          // {
          //   label: "Select Scheme",
          //   name: "schemename",
          //   value: "",
          //   type: "dropdown",
          //   class: "col-md-4",
          //   dropdownname: "schemename",
          //   required: true,
          //   options: [],
          // },
          // {
          //   label: "Reference Client",
          //   name: "refclient",
          //   value: "",
          //   type: "dropdown",
          //   class: "col-md-4",
          //   dropdownname: "refclient",
          //   required: true,
          //   options: [],
          // },
          {
            label: "Booking Date",
            name: "bookingdate",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Enter bookingdate",
            class: "col-md-4",
            required: true,
          },
          // {
          //   label: "Free Plot",
          //   name: "freeplot",
          //   value: "",
          //   type: "dropdown",
          //   class: "col-md-4",
          //   dropdownname: "freeplot",
          //   required: true,
          //   options: [],
          // },
          // {
          //   label: "Certificate Code",
          //   name: "certificatecode",
          //   value: "",
          //   type: "text",
          //   placeholder: "Enter Certificate Code",
          //   class: "col-md-4",
          //   required: true,
          // },
          {
            label: "Client Type",
            name: "clienttype",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "clienttype",
            required: true,
            options: [],
          },
          {
            type: "title",
            title: "Application Form",
            class: "col-md-12",
          },
          {
            label: "Select Project",
            name: "project_id",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "project_id",
            required: true,
            options: [],
          },
          {
            label: "Select Phase",
            name: "phase_id",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "phase_id",
            required: true,
            options: [],
          },
          {
            label: "Suggested Plot No",
            name: "suggestedplotno_id",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "suggestedplotno_id",
            required: true,
            options: [],
          },
          // {
          //   label: "Total Installment",
          //   name: "certificatecode",
          //   value: "",
          //   type: "text",
          //   placeholder: "Enter Certificate Code",
          //   class: "col-md-4",
          //   required: true,
          // },
          {
            label: "Plot Rate",
            name: "plotrate",
            value: "",
            type: "number",
            placeholder: "Enter Plot Rate",
            class: "col-md-4",
            required: true,
          },
          {
            label: "Plot Area",
            name: "plotarea",
            value: "",
            type: "number",
            placeholder: "Enter Plot Area",
            class: "col-md-4",
            required: true,
          },
          {
            label: "Plot Area/Sq.Feet",
            name: "plotareasquarefeet",
            value: "",
            type: "number",
            class: "col-md-6",
            placeholder: "Enter Plot Area/Sq.Feet",
            disable: true,
          },
          // {
          //   label: "Promo ID",
          //   name: "promoid",
          //   value: "",
          //   type: "dropdown",
          //   class: "col-md-6",
          //   dropdownname: "promoid",
          //   required: true,
          //   options: [],
          // },
          // {
          //   label: "RS/%",
          //   name: "plotdiscountper",
          //   value: "",
          //   type: "dropdown",
          //   class: "col-md-6",
          //   dropdownname: "plotdiscountper",
          //   required: true,
          //   options: [],
          // },
          {
            label: "Discount",
            name: "discount",
            value: "",
            type: "number",
            class: "col-md-6",
            placeholder: "Enter Discount",
            disable: true,
          },
          {
            label: "Plot Value",
            name: "plotvalue",
            value: "",
            type: "number",
            class: "col-md-6",
            placeholder: "Enter Plot Value",
            disable: true,
          },
          {
            label: "Docket No",
            name: "docketno",
            value: "",
            type: "number",
            class: "col-md-6",
            placeholder: "Enter Docket No",
          },
          {
            label: "EMI For",
            name: "emifor",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "emifor",
            required: true,
            options: [
              { name: "3 Years" },
              { name: "5 Years" },
              { name: "10 Years" },
            ],
          },
          {
            label: "Payment Terms Days",
            name: "paymenttermday",
            value: "",
            type: "number",
            class: "col-md-6",
            placeholder: "Enter Payment Terms Days",
          },
          {
            type: "title",
            title: "Payment Details",
            class: "col-md-12",
          },
          {
            name: "paymenttype",
            value: "bycash",
            type: "radio",
            options: [
              { text: "By Cash", value: "bycash", disabled: false },
              { text: "By Check", value: "bycheck", disabled: false },
              { text: "By RTGS", value: "byrtgs", disabled: false },
              { text: "By NEFT", value: "byneft", disabled: false },
              { text: "By ECS", value: "byecs", disabled: false },
              {
                text: "Debit/Credit Card",
                value: "debitcreditcard",
                disabled: false,
              },
              { text: "Net Banking", value: "netbanking", disabled: false },
              { text: "Barter", value: "barter", disabled: false },
              { text: "Free Plot", value: "freeplot", disabled: false },
            ],
          },
          {
            label: "Total Payment",
            name: "totalpayment",
            value: "",
            type: "number",
            class: "col-md-6",
            placeholder: "Enter Total Payment",
            disable: true,
          },
          {
            label: "First Payment",
            name: "firstpayment",
            value: "",
            type: "number",
            class: "col-md-6",
            placeholder: "Enter First Payment",
          },
          {
            label: "Remaining Payment",
            name: "remainingpayment",
            value: "",
            type: "number",
            class: "col-md-6 mr-2 ",
            placeholder: "Enter Remaining Payment",
            disable: true,
          },
          {
            label: "Ledger",
            name: "ledger",
            value: "",
            type: "dropdown",
            class: "col-md-6 ",
            dropdownname: "ledger",
            required: true,
            options: [],
          },
          {
            label: "Installment Date",
            name: "installmentdate",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Enter Installment Date",
            class: "col-md-6",
            required: true,
          },
          // {
          //   label: "RS/%",
          //   name: "plotdiscountper",
          //   value: "",
          //   type: "dropdown",
          //   class: "col-md-3",
          //   dropdownname: "plotdiscountper",
          //   required: true,
          //   options: [],
          // },
          {
            label: "Consultant",
            name: "consultant",
            value: "",
            type: "text",
            class: "col-md-3",
            placeholder: "Enter Consultant",
          },
          {
            label: "Consultant Name",
            name: "consultantid",
            value: "",
            type: "dropdown",
            class: "col-md-3",
            dropdownname: "consultantid",
            required: true,
            options: [],
          },
          {
            label: "Dealer Commission",
            name: "dealercommission",
            value: "",
            type: "number",
            class: "col-md-3",
            placeholder: "Enter Dealer Commission",
            disable: true,
          },
          {
            label: "RS/%",
            name: "plotdiscountper",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "plotdiscountper",
            required: true,
            options: [],
          },
          {
            label: "Employee Name",
            name: "employeeid",
            value: "",
            type: "dropdown",
            class: "col-md-3",
            dropdownname: "employeeid",
            required: true,
            options: [],
          },
          {
            label: "Employee Commission",
            name: "employeecommission",
            value: "",
            type: "number",
            class: "col-md-3",
            placeholder: "Enter Employee Commission",
            disable: true,
          },
          {
            label: "Ledger",
            name: "ledger",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Ledger",
          },
          {
            label: "Ledger Name",
            name: "ledgerid",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "ledgerid",
            required: true,
            options: [],
          },
          {
            label: "*",
            name: "ledger",
            value: "",
            type: "text",
            class: "col-md-4",
          },
          {
            label: "Business Manager",
            name: "businessmanager",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Business Manager",
          },
          {
            label: "Business Manager Name",
            name: "bmid",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "bmid",
            required: true,
            options: [],
          },
          {
            label: "*",
            name: "businessmanager",
            value: "",
            type: "text",
            class: "col-md-4",
          },
          {
            label: "Sr. Relationship manager ",
            name: "relationshipmanager",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Sr. Relationship manager ",
          },
          {
            label: "Sr. Relationship Name",
            name: "relationshipid",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "relationshipid",
            required: true,
            options: [],
          },
          {
            label: "*",
            name: "relationship",
            value: "",
            type: "text",
            class: "col-md-4",
          },
          {
            label: "Deputy. CEO",
            name: "deputyceo",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Deputy. CEO",
          },
          {
            label: "Deputy. CEO Name",
            name: "deputyceoid",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "deputyceoid",
            required: true,
            options: [],
          },
          {
            label: "*",
            name: "deputy",
            value: "",
            type: "text",
            class: "col-md-4",
          },
          {
            label: "CEO",
            name: "ceo",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter CEO",
          },
          {
            label: "CEO Name",
            name: "ceoid",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "ceoid",
            required: true,
            options: [],
          },
          {
            label: "*",
            name: "ceo",
            value: "",
            type: "text",
            class: "col-md-4",
          },

          {
            type: "title",
            title: "Application Name",
            class: "col-md-12",
          },
          {
            label: "Branch Name",
            name: "branchname",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Branch Name ",
          },
          {
            label: "Title",
            name: "titleid",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "titleid",
            required: true,
            options: [],
          },
          {
            label: "Customer name",
            name: "customername",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Customer name",
          },
          {
            label: "Middle name ",
            name: "middlename ",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Middle name ",
          },
          {
            label: "Last name",
            name: "lastname",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Last name",
          },
          {
            label: "Address",
            name: "address",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Address",
          },
          {
            label: "City",
            name: "city",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter City",
          },
          {
            label: "State",
            name: "state",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter State",
          },
          {
            label: "Country",
            name: "country",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Country",
          },
          {
            label: "Pincode",
            name: "pincode",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Pincode",
          },
          {
            label: "DOB",
            name: "dob",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Date of Birth",
          },
          {
            label: "Phone no.",
            name: "phonr",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Phone no.",
          },
          {
            label: "Mobile No.",
            name: "mobile",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Mobile No.",
          },
          {
            label: "Email Id.",
            name: "emailid",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Email Id.",
          },
          {
            label: "Promo Code",
            name: "promocode",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Promo Code",
          },
          {
            label: "Addhar no.",
            name: "addhar",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Addhar no.",
          },
          {
            label: "Pan No",
            name: "pan",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Pan No",
          },
          {
            label: "Send Email",
            name: "sendemailid",
            value: "",
            type: "dropdown",
            class: "col-md-4",
            dropdownname: "sendemailid",
            required: true,
            options: [{ name: "Yes" }, { name: "No" }],
          },
          {
            label: "Nominee",
            namen: "nominee",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Nominee",
          },
          {
            label: "Nominee Relationship",
            name: "nomineerelationship",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Nominee Relationship",
          },
          {
            label: "Nominee Phone",
            name: "nomineephone",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Nominee Phone",
          },
          {
            type: "title",
            title: "User Details",
            class: "col-md-12",
          },
          {
            label: "Client User Name",
            name: "clientusername",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Client User Name",
          },
          {
            label: "Upload image",
            name: "uploadimage",
            value: "",
            type: "fileinput",
            placeholder: "select Attechment 3",
            class: "col-md-4",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
          {
            label: "Upload Document",
            name: "uploaddocument",
            value: "",
            type: "fileinput",
            placeholder: "Upload Document",
            class: "col-md-4",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
          {
            label: "Upload Document",
            name: "uploaddocument",
            value: "",
            type: "fileinput",
            placeholder: "Upload Document",
            class: "col-md-12",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
          {
            label: "Image 1",
            name: "image1",
            value: "",
            type: "fileinput",
            placeholder: "Image 1",
            class: "col-md-4",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
          {
            label: "Image 2",
            name: "image2",
            value: "",
            type: "fileinput",
            placeholder: "Image 2",
            class: "col-md-4",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
          {
            label: "Image 3",
            name: "image3",
            value: "",
            type: "fileinput",
            placeholder: "Image 3",
            class: "col-md-4",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
          {
            type: "title",
            title: "Only For Office Use",
            class: "col-md-12",
          },
          {
            label: "Contact Name",
            name: "contactname",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Contact Name ",
          },
          {
            label: "Contact Number",
            name: "contactnumber",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Contact Number ",
          },
          {
            label: "Contact Email",
            name: "contactemail",
            value: "",
            type: "text",
            class: "col-md-4",
            placeholder: "Enter Contact Email ",
          },
          {
            type: "title",
            title: "INSTALLMENTS",
            class: "col-md-12",
          },
          {
            label: "",
            name: "",
            type: "",
            class: "col-md-8",
          },
          {
            label: "Remaining Amount",
            name: "remainingamount",
            value: "",
            type: "number",
            class: "col-md-4 ",
            placeholder: "Enter Remaining Amount",
          },
          {
            label: "Installment start date:",
            name: "installmentstartdate",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Enter Installment start date:",
            class: "col-md-3",
            required: true,
          },
          {
            label: "No. of Installment",
            name: "no.of.installment ",
            value: "",
            type: "number",
            class: "col-md-3",
            placeholder: "Enter No. of Installment ",
          },
          {
            label: "Gap between months:",
            name: "gapbetweenmonths",
            value: "",
            type: "number",
            class: "col-md-3",
            placeholder: "Enter Gap between months: ",
          },
          {
            label: "Installment Amount:",
            name: "installmentamount",
            value: "",
            type: "number",
            class: "col-md-3",
            placeholder: "Enter Installment Amount:",
          },
          {
            title: "",
            type: "formrepeater",
            inputFields: [
              {
                label: "Sr .No",
                name: "srno",
                value: 1,
                type: "number",
                class: "col-md-1",
                // required: {
                //   required,
                // },
              },
              {
                label: "Installment date",
                name: "installmentdate",
                value: moment(new Date()).format("yyyy-MM-DD"),
                type: "date",
                placeholder: "Enter Installment date",
                class: "col-md-3",
                required: true,
              },
              {
                label: "Installment Amount",
                name: "installmentamount",
                value: "",
                type: "text",
                class: "col-md-3",
                // required: {
                //   required,
                // },
              },
              {
                label: "Remarks",
                name: "remarks",
                value: "",
                type: "text",
                class: "col-md-3",
                // required: {
                //   required,
                // },
              },
            ],
          },
          {
            label: "No. of installment :",
            name: "no.of.installment ",
            value: "",
            type: "number",
            class: "col-md-2",
            placeholder: "Enter No. of Installment ",
          },
          {
            label: "No. of installment Amount",
            name: "No.of.installmentamount",
            value: "",
            type: "number",
            class: "col-md-6",
            placeholder: "Enter No. of installment Amount",
          },
        ],
      },
      columns: [
        // {
        //   label: "ID",
        //   field: "id",
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: "Search ID",
        //   },
        // },
        // {
        //   label: "Company Name",
        //   field: "companyname",
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: "Search Company Name",
        //   },
        // },
        // {
        //   label: "Team",
        //   field: "team",
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: "Search Team",
        //   },
        // },
        {
          label: "Sr. No",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search Channel Partner",
          },
          hidden: true,
        },
        {
          label: "Year",
          field: "year",
          filterOptions: {
            enabled: true,
            placeholder: "Search Year",
          },
        },
        // {
        //   label: 'Month',
        //   field: 'month',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search Month'
        //   }
        // },
        {
          label: "Employee",
          field: "employee_id",
          type: "dropdown",
          url: "team",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Employee",
          },
        },
        {
          label: "No of CP / Franchise",
          field: "totalcf",
          filterOptions: {
            enabled: true,
            placeholder: "Search No of CP / Franchise",
          },
        },
        {
          label: "Amount",
          field: "totalamount",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Target") {
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
